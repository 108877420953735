import React from 'react';
import styles from './styles/VideoLinks.module.scss';

const VideoLinks = ({module}) => (
    <div className={styles.grid}>
        {module.links.map((item, index) => (
            <div className={styles.item} key={index}>
                <div className={styles.playerWrapper}>
                    <iframe
                        className={styles.player}
                        src={item}
                        frameBorder={0}
                        allow="fullscreen"
                    />
                </div>
            </div>
        ))}
    </div>
);

export default VideoLinks;
