import React from 'react';
import {graphql} from 'gatsby';
import {Helmet} from 'react-helmet';
import Layout from '../components/Layout';
import Modules from '../components/modules/Modules';

const VideosPage = ({data}) => (
    <>
        <Helmet>
            <title>Videos : {data.site.siteMetadata.title}</title>
            <meta
                name="description"
                content={`Videos : ${data.site.siteMetadata.description}`}
            />
            <meta
                name="og:title"
                content={`Videos : ${data.site.siteMetadata.title}`}
            />
            <meta
                name="og:description"
                content={`Videos : ${data.site.siteMetadata.description}`}
            />
            <meta
                name="og:url"
                content={`${data.site.siteMetadata.url}/videos`}
            />
            <meta name="og:type" content="website" />
        </Helmet>
        <Layout>
            <Modules modules={data.contentfulPageContent.modules} />
        </Layout>
    </>
);

export default VideosPage;

export const query = graphql`
query {
  site {
    ...SiteMetadata
  }
  contentfulPageContent(title: {eq: "Videos"}) {
    modules {
      __typename
      ... on ContentfulModuleVideoLinks {
        id
        links
      }
    }
  }
}    
`;
