import React from 'react';
import VideoLinks from './VideoLinks';

const mapping = {
    ContentfulModuleVideoLinks: VideoLinks,
};

const Modules = ({modules}) => (
    <>
        {modules.map((m) => {
            const Component = mapping[m.__typename];

            if (!Component) {
                throw new Error(`Content module for type ${m.__typename} not registered.`);
            }

            return <Component module={m} key={m.id} />;
        })}
    </>
);

export default Modules;
